import React, { useState } from "react";
import PropTypes from "prop-types";
import { Layout } from 'antd';
import  MediaQuery from 'react-responsive'
import Sidebar from "../components/layout/Sidebar";
import {
  CloseCircleOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

const { Content, Sider } = Layout;

const DefaultLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <React.Fragment>
      <Layout className="main-wrapper">
        <MediaQuery maxWidth={1200}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : CloseCircleOutlined, {
              className: 'menu-trigger',
              onClick: () => setCollapsed(!collapsed),
          })}
          {/* <div className='menu-trigger' onClick={() => setCollapsed(!collapsed)}>{collapsed ? <><SvgIcon name='meu' viewbox='0 0 30 24.392' /></> : <><SvgIcon name='close-line' viewbox='0 0 24.398 24.392' /></>}</div> */}
        </MediaQuery>
        <MediaQuery maxWidth={1200}>
          <Sider
            breakpoint="xl"
            width={240}
            collapsedWidth="0"    
            collapsed={collapsed} 
            trigger={null}     
          >
            <Sidebar />
          </Sider>
        </MediaQuery>
        <MediaQuery minWidth={1200}>
          <Sider
            breakpoint="xl"
            width={240}
            collapsedWidth="0"          
          >
            <Sidebar />
          </Sider>
        </MediaQuery>
        <Layout>
          <Content>
            <main>
              {children}
            </main>
          </Content>
        </Layout>
      </Layout>
    </React.Fragment>
  )
};

DefaultLayout.propTypes = {
  navbar: PropTypes.bool,
  footer: PropTypes.bool
};

DefaultLayout.defaultProps = {
  navbar: false,
  footer: false
};

export default DefaultLayout;