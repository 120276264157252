import React, { useState, useEffect } from 'react';
import { Button, DatePicker, Input, Select, Table, Dropdown, Menu } from 'antd';
import { Col, Row, SvgIcon } from '../../components/common';
import { useHistory } from 'react-router-dom';
import HttpClient from "../../api/HttpClient";
import './index.scss';
import { showAlert } from "../../common/alert-messages";
import inAppicon from '../../assets/images/inapp.png';
import appleicon from '../../assets/images/applepay.png';
import gpayicon from '../../assets/images/gpay.png';
import moment from 'moment';

import tpIcon from '../../assets/images/tp_icon.svg';

const Cancellations = () => {
    const history = useHistory();
    
    const { Option } = Select;
    useEffect(() => {
        fetchData();
    }, []);

    const [BookingData, setBookingData] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [collectedAmount, setcollectedAmount] = useState(0);
    const [totalBookings, settotalBookings] = useState(0);
    const [settledAmount, setsettledAmount] = useState(0);
    const [activeTours, setactiveTours] = useState(0);
    const [dataInCSV, setDataInCSV] = useState("");
    const [DateFrom, setDateFrom] = useState(null);
    const [DateTo, setDateTo] = useState(null);
    const [SelectYear, setSelectYear] = useState("2023");
    const [SelectMonth, setSelectMonth] = useState("January");


    const monthNames = [ "January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December" ];
    const yearNames = [ "2016", "2017", "2018", "2019", "2020", "2021","2023", "2024", "2025", "2026", "2028", "2029","2030" ];
    const fetchData = async () => {
        try {

            if (localStorage.getItem('Login') === null) {
                showAlert.error('Oops! Please Login to Continue.');
                history.push("/");
            }

            const response = await HttpClient.post("bookings/getCancellationsData");
            const json = response.data;

            if (json.status === 1) {
                //console.log(json.bookingStats.collected);
                setcollectedAmount(json.bookingStats.setteled);
                settotalBookings(json.bookingStats.bookings);
                setsettledAmount(json.bookingStats.cancelled_amount);
                setactiveTours(json.bookingStats.activeTours);
                setDataSource(json.cancellationList);

            } else {
                console.error(json);
            }



        } catch (error) {
            console.error(error);
        }
    };

    const onSearch = (e) => {
        const query = e.target.value
        if (query) {
            const reg = new RegExp(query, "gi");
            const filterData = dataSource.filter((row) => {
                const tourName = row.tourName.match(reg);
                const vEmail = row.vEmail.match(reg);
                const referenceNo = row.referenceNo.match(reg);
                return tourName || vEmail || referenceNo;
            })
            setDataSource(filterData)
        } else {
            setDataSource(dataSource)
        }
    };

    const downloadCsv = async () => {
        try {
            const response = await HttpClient.post("/bookings/getPaymentsDataCsv");
            const json = response.data;
            setDataInCSV(json);

            //console.log(json);
        }
        catch (error) {
            console.error(error);
            showAlert.error("Oops! Something went wrong.");
        }
    };

    const handleFrom = (date, dateString) => {
        setDateFrom(date);
    };
    const handleTo = (date, dateString) => {
        setDateTo(date);

        if (DateFrom) {
            console.log(DateFrom.format("DD-MM-YYYY"));
        }

    };

    const getReport = async () => {
        const data = new FormData();
       
            data.append("SelectMonth", SelectMonth);
            data.append("SelectYear", SelectYear);

            const response_data = await HttpClient.post("/bookings/checkBookings",data);
            const json = response_data.data;

            if(json.bookingStats===0){
                showAlert.error("No Bookings Found.");
            }
            else{

                const response = await HttpClient.post("/bookings/generateReport",data,{responseType:"arraybuffer"}); 
                //console.log(response.data);return;
                download(response.data,SelectMonth,SelectYear);

            }
        
    };


    function download(content,SelectMonth,SelectYear) {
        const file = new File([content],`DiscoverPaymentReportData_${SelectMonth}${SelectYear}.pdf`, {
          type: 'application/pdf',
        })
        
        
          const link = document.createElement('a')
          const url = URL.createObjectURL(file)
        
          link.href = url
          link.download = file.name
          document.body.appendChild(link)
          link.click()
        
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        }
    const handleMonth = (value, options) => {
        setSelectMonth(options.children);
    };
    const handleYear = (value, options) => {
        setSelectYear(options.children);
    };

    const handleDateRange = async () => {
        try {

            const data = new FormData();

            data.append("startDate", DateFrom);
            data.append("endDate", DateTo);
            const response = await HttpClient.post("/bookings/getPaymentsDataDateRange", data);
            const json = response.data;
            if (json.status === 1) {
                setcollectedAmount(json.bookingStats.collected);
                settotalBookings(json.bookingStats.bookings);
                //setupcomingBooking(json.bookingStats.upcoming_bookings);
                //setcompletedBooking(json.bookingStats.completed_bookings);
                setDataSource(json.bookingList);

            } else {
                showAlert.error("Oops! Something went wrong.");
            }
        } catch (error) {
            console.error(error);
            showAlert.error("Oops! Something went wrong.");
        }
    };



    const columns = [
        {
            title: 'Tour ID',
            dataIndex: 'tourId',
            key: 'tourId',
            //render: (text, record) => (<div className='name-with-icon'><div className='table-image-icon'><img src={record.imagePath} alt={record.tourName} /></div> {record.tourName} </div>),
            sorter: (a, b) => a.tourId - b.tourId,
            width: 120
        },
        {
            title: '',
            dataIndex: 'tourName',
            key: 'tourName',
            render: (text, record) => (<div className='name-with-icon'><div className='table-image-icon'><img src={record.imagePath} alt={record.tourName} /></div> </div>),
            //sorter: (a, b) => a.tourName - b.tourName,
            width: 60
        },
        {
            title: 'Package Name',
            dataIndex: 'tourName',
            key: 'tourName',
            //render: (text, record) => (<div className='name-with-icon'><div className='table-image-icon'><img src={record.imagePath} alt={record.tourName} /></div> {record.tourName} </div>),
            sorter: (a, b) => a.tourName - b.tourName,
            width: 140
        },
        {
            title: 'Tour Option ID',
            dataIndex: 'tourOptionId',
            key: 'tourOptionId',
            //render: (text, record) => (<div className='name-with-icon'><div className='table-image-icon'><img src={record.imagePath} alt={record.tourName} /></div> {record.tourName} </div>),
            sorter: (a, b) => a.tourOptionId - b.tourOptionId,
            width: 120
        },
        {
            title: 'Tour Option Name',
            dataIndex: 'optionName',
            key: 'optionName',
            //render: (text, record) => (<div className='name-with-icon'><div className='table-image-icon'><img src={record.imagePath} alt={record.tourName} /></div> {record.tourName} </div>),
            sorter: (a, b) => a.optionName - b.optionName,
            width: 140
        },
        {
            title: 'Type of cancellation',
            dataIndex: 'cancellationType',
            key: 'cancellationType',
            //render: (text, record) => (<div className='name-with-icon'><div className='table-image-icon'><img src={record.imagePath} alt={record.tourName} /></div> {record.tourName} </div>),
            sorter: (a, b) => a.cancellationType - b.cancellationType,
            width: 140
        },
        {
            title: 'Customer',
            dataIndex: 'vUserName',
            key: 'vUserName',
            sorter: (a, b) => a.vUserName - b.vUserName,
            width: 100
        },
        {
            title: 'User Email',
            dataIndex: 'vEmail',
            key: 'vEmail',
            sorter: (a, b) => a.vEmail - b.vEmail,
            width: 120
        },
        {
            title: 'User Mobile No.',
            dataIndex: 'vMobileNo',
            key: 'vMobileNo',
            sorter: (a, b) => a.vMobileNo - b.vMobileNo,
            width: 120
        },
        {
            title: 'Booking Date',
            dataIndex: 'tourDate',
            key: 'tourDate',
            sorter: (a, b) => moment(a.tCreatedAt).unix() - moment(b.tCreatedAt).unix(),
            width: 120
        },
        {
            title: 'Cancellation Date',
            dataIndex: 'tCreatedAt',
            key: 'tCreatedAt',
            sorter: (a, b) => moment(a.tCreatedAt).unix() - moment(b.tCreatedAt).unix(),
            width: 120
        },
        {
            title: 'User Paid (Payment Received from user)',
            dataIndex: 'tpServiceTotalUC',
            key: 'tpServiceTotalUC',
            sorter: (a, b) => a.tpServiceTotalUC - b.tpServiceTotalUC,
            render: (text, record) => (<div className='spent-col'><small>{record.uCurrency}</small><div className='amount-col'>{text}</div></div>),
            width: 180
        },
        {
            title: 'B2C Pricing (Amount with Commission in Original Currency)',
            dataIndex: 'tpServiceTotal',
            key: 'tpServiceTotal',
            sorter: (a, b) => a.tpServiceTotal - b.tpServiceTotal,
            render: (text, record) => (<div className='spent-col'><small>AED</small><div className='amount-col'>{text}</div></div>),
            width: 180
        },
    ];
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: 'Download Report',
                }
            ]}
        />
    );
    return (
        <div className='inner-wrapper'>
            <Row>
                <Col>
                    <ul className='statcis-row'>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='payment' viewbox='0 0 15.842 12.5' />
                                </div>
                                <div className='item-upper'>
                                    <small>AED</small>
                                    <h3>{settledAmount}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Refunded</p>
                                    {/* <p>Cancellations</p>
                                    <small>Refunded After <br></br>5% Commission</small> */}
                                </div>
                            </div>
                            <SvgIcon className='total-sale-icon' name='total-sale' viewbox='0 0 99.973 46.186' />
                        </li>
                       
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='offer' viewbox='0 0 15.532 15.553' />
                                </div>
                                <div className='item-upper'>
                                    <small>AED</small>
                                    <h3>{collectedAmount}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Commissions Total</p>
                                    <small>Amount Retained from Cancellations</small>
                                </div>
                            </div>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row className="filter-row">
                <Col md="7">
                    <div className='filter-left'>
                        <h3>Cancellations</h3>
                        <Input onChange={onSearch} suffix={<SvgIcon name='search' viewbox='0 0 15.13 15.134' />} />
                    </div>
                </Col>
                {/* <Col md="4" className='filter-right'>
                     <div className='date-select-row'>
                        <div className='date-col'>
                            <label>Month</label>
                            <Select style={{width: 100,}}
                                    defaultValue="January"
                                    popupClassName='header-top-select'
                                    placement="bottomRight" onChange={handleMonth} value={SelectMonth}
                                    suffixIcon={<SvgIcon name='select-arrow' viewbox='0 0 18 9' />}>
                                    {monthNames.map(item =>
                                    (
                                        <Option value={item}>{item}</Option>
                                    )
                                    )}
                            </Select>
                        </div>
                        <div className='date-col'>
                            <label>Year</label>
                            <Select style={{width: 100,}}
                                    defaultValue="2023"
                                    popupClassName='header-top-select'
                                    placement="bottomRight" onChange={handleYear} value={SelectYear}
                                    suffixIcon={<SvgIcon name='select-arrow' viewbox='0 0 18 9' />}>
                                    
                                    {yearNames.map(item =>
                                    (
                                        <Option value={item}>{item}</Option>
                                    )
                                    )}
                            </Select>
                        </div>
                    </div>
                    
                </Col> */}
                <Col md="5" className='filter-right'>
                    <div className='date-select-row'>
                        <div className='date-col'>
                            <label>Starting Date</label>
                            <DatePicker value={DateFrom} onChange={handleFrom} inputReadOnly placeholder="DD/MM/YYYY" suffixIcon={<SvgIcon name='calendar' viewbox='0 0 38.269 36' />} />
                        </div>
                        <div className='date-col'>
                            <label>End Date</label>
                            <DatePicker value={DateTo} onChange={handleTo} inputReadOnly placeholder="DD/MM/YYYY" suffixIcon={<SvgIcon name='calendar' viewbox='0 0 38.269 36' />} />
                        </div>
                    </div>
                    <Button type='secondary' onClick={handleDateRange} className='icons-btn send-btn'><SvgIcon name='send' viewbox='0 0 26.998 26.995' /></Button>
                    <Dropdown overlayClassName='dropdown-popup-cus' overlay={menu}>
                        <Button type='secondary' onClick={downloadCsv} className='icons-btn'><SvgIcon name='download' viewbox='0 0 15.624 15.996' /></Button>

                    </Dropdown>
                </Col>
            </Row>
            <Row>
                <Col sm='12'>
                    <Table className='custom-table outletmgt-table' scroll={{ x: "100%", y: "100%" }} dataSource={dataSource} columns={columns} showSorterTooltip={false} sortUpIcon={<SvgIcon name='heart' viewbox='0 0 14.297 12.626' />} />
                </Col>
            </Row>
        </div>
    )
};

export default Cancellations