import React, { useState, useEffect } from 'react';
import { Button, DatePicker, Input, Select, Table, Dropdown, Menu, Modal, Divider } from 'antd';
import { Col, Row, SvgIcon } from '../../components/common';
import { useHistory } from 'react-router-dom';
import HttpClient from "../../api/HttpClient";
import './index.scss';
import { showAlert } from "../../common/alert-messages";
import moment from 'moment';

import Platform1 from '../../assets/images/pt1.png';
import Platform2 from '../../assets/images/pt2.png';
import Platform3 from '../../assets/images/pt3.png';
import Platform4 from '../../assets/images/pt4.png';
import Platform5 from '../../assets/images/pt5.png';
import Platform6 from '../../assets/images/pt6.png';
import inAppicon from '../../assets/images/inapp.png';
import appleicon from '../../assets/images/applepay.png';
import gpayicon from '../../assets/images/gpay.png';

const Bookings = () => {

    const history = useHistory();
    useEffect(() => {
        fetchData();
    }, []);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [BookingData, setBookingData] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [collectedAmount, setcollectedAmount] = useState(0);
    const [totalBookings, settotalBookings] = useState(0);
    const [upcomingBooking, setupcomingBooking] = useState(0);
    const [completedBooking, setcompletedBooking] = useState(0);
    const [dataInCSV, setDataInCSV] = useState("");
    const [DateFrom, setDateFrom] = useState(null);
    const [DateTo, setDateTo] = useState(null);

    const fetchData = async () => {
        try {

            if (localStorage.getItem('Login') === null) {
                showAlert.error('Oops! Please Login to Continue.');
                history.push("/");
            }

            localStorage.removeItem("startDate");
            localStorage.removeItem("endDate");
            const response = await HttpClient.post("bookings/getBookingsData");
            const json = response.data;

            if (json.status === 1) {
                //console.log(json.bookingStats.collected);
                setcollectedAmount(json.bookingStats.collected);
                settotalBookings(json.bookingStats.bookings);
                setupcomingBooking(json.bookingStats.upcoming_bookings);
                setcompletedBooking(json.bookingStats.completed_bookings);
                setDataSource(json.bookingList);

            } else {
                console.error(json);
            }



        } catch (error) {
            console.error(error);
        }
    };


    const showModal = (record) => {
        //console.log(record);
        setBookingData(record);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const onSearch = (e) => {
        const query = e.target.value
        if (query) {
            const reg = new RegExp(query, "gi");
            const filterData = dataSource.filter((row) => {
                const tourName = row.tourName.match(reg);
                const vEmail = row.vEmail.match(reg);
                const referenceNo = row.referenceNo.match(reg);
                return tourName || vEmail || referenceNo;
            })
            setDataSource(filterData)
        } else {
            setDataSource(dataSource)
        }
    };

    const downloadCsv = async () => {
        try {

            var dateItem = localStorage.getItem('startDate');
            if (!dateItem){
                const response = await HttpClient.post("/bookings/getBookingsDataCsv");
                const json = response.data;
                const file = new File([json],`DiscoverPaymentReportDataCSV.csv`, {
                    type: 'application/csv',
                  })
                  
                  
                    const link = document.createElement('a')
                    const url = URL.createObjectURL(file)
                  
                    link.href = url
                    link.download = file.name
                    document.body.appendChild(link)
                    link.click()
                  
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
            }
            else{
                var DateFrom = localStorage.getItem('startDate');
                var DateTo = localStorage.getItem('endDate');
                const data = new FormData();
    
                data.append("startDate", DateFrom);
                data.append("endDate", DateTo);
                const response = await HttpClient.post("/bookings/getBookingsDataCsvRange", data);
                const json = response.data;
                const file = new File([json],`DiscoverPaymentReportDataCSV.csv`, {
                    type: 'application/csv',
                  })
                  
                  
                    const link = document.createElement('a')
                    const url = URL.createObjectURL(file)
                  
                    link.href = url
                    link.download = file.name
                    document.body.appendChild(link)
                    link.click()
                  
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                
            }
           
           
            //setDataInCSV(json);

            

            //console.log(json);
        }
        catch (error) {
            console.error(error);
            showAlert.error("Oops! Something went wrong.");
        }
    };

    const getReport = async (rBookingID) => {
            const data = new FormData();
            //console.log(rBookingID);
            data.append("rBookingID", rBookingID);

            const response = await HttpClient.post("/bookings/downloadTicket",data,{responseType:"arraybuffer"}); 
                //console.log(response.data);return;
            download(response.data);

               
    };

    function download(content) {
        const file = new File([content],`Ticket.pdf`, {
          type: 'application/pdf',
        })
        
        
          const link = document.createElement('a')
          const url = URL.createObjectURL(file)
        
          link.href = url
          link.download = file.name
          document.body.appendChild(link)
          link.click()
        
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        }

    const handleFrom = (date, dateString) => {
        
        localStorage.setItem("startDate",date);
        setDateFrom(date);
    };
    const handleTo = (date, dateString) => {
        localStorage.setItem("endDate",date);
        setDateTo(date);

        if (DateFrom) {
            console.log(DateFrom.format("DD-MM-YYYY"));
        }


    };

    const handleDateRange = async () => {
        try {

            const data = new FormData();

            data.append("startDate", DateFrom);
            data.append("endDate", DateTo);


            const response = await HttpClient.post("/bookings/getBookingsDataDateRange", data);
            const json = response.data;
            if (json.status === 1) {
                setcollectedAmount(json.bookingStats.collected);
                settotalBookings(json.bookingStats.bookings);
                setupcomingBooking(json.bookingStats.upcoming_bookings);
                setcompletedBooking(json.bookingStats.completed_bookings);
                setDataSource(json.bookingList);

            } else {
                showAlert.error("Oops! Something went wrong.");
            }
        } catch (error) {
            console.error(error);
            showAlert.error("Oops! Something went wrong.");
        }
    };

    // const dataSource = [
    //     {
    //         key: '1',
    //         package: 'Deem Offers',
    //         image: Platform1,
    //         user_email: 'imcdigitaltest@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: inAppicon,
    //         payment_mode: 'In-app payment',
    //         customer: '2 for 1',
    //         date: '12th Mar 2022',
    //         amount: '230'
    //     },
    //     {
    //         key: '2',
    //         package: 'Ahli Rewards',
    //         image: Platform3,
    //         user_email: 'arvindpatel@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: appleicon,
    //         payment_mode: 'Apple Pay',
    //         customer: '2 for 1',
    //         date: '12th Mar 2022',
    //         amount: '230'
    //     },
    //     {
    //         key: '3',
    //         package: 'CBD',
    //         image: Platform4,
    //         user_email: 'imcdigitaltest@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: gpayicon,
    //         payment_mode: 'Gpay',
    //         customer: '2 for 1',
    //         date: '12th Mar 2022',
    //         amount: '429'
    //     },
    //     {
    //         key: '4',
    //         package: 'ENBD Bank',
    //         image: Platform2,
    //         user_email: 'imcdigitaltest@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: inAppicon,
    //         payment_mode: 'In-app payment',
    //         customer: '2 for 1',
    //         date: '12th Mar 2022',
    //         amount: '748'
    //     },
    //     {
    //         key: '5',
    //         package: 'Deem Offers',
    //         image: Platform1,
    //         user_email: 'imcdigitaltest@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: inAppicon,
    //         payment_mode: 'In-app payment',
    //         customer: '2 for 1',
    //         date: '12th Mar 2022',
    //         amount: '132'
    //     },
    //     {
    //         key: '6',
    //         package: 'TP',
    //         image: Platform5,
    //         user_email: 'imcdigitaltest@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: appleicon,
    //         payment_mode: 'Apple Pay',
    //         customer: 'Discount',
    //         date: '12th Mar 2022',
    //         amount: '230'
    //     },
    //     {
    //         key: '7',
    //         package: 'CBD',
    //         image: Platform3,
    //         user_email: 'imcdigitaltest@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: gpayicon,
    //         payment_mode: 'Gpay',
    //         customer: '2 for 1',
    //         date: '12th Mar 2022',
    //         amount: '748'
    //     },
    //     {
    //         key: '8',
    //         package: 'Discover',
    //         image: Platform6,
    //         user_email: 'imcdigitaltest@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: gpayicon,
    //         payment_mode: 'Paid at outlet',
    //         customer: '2 for 1',
    //         date: '12th Mar 2022',
    //         amount: '132'
    //     },
    // ];

    const columns = [
        {
            title: '',
            dataIndex: 'tourName',
            key: 'tourName',
            render: (text, record) => (<div className='name-with-icon'><div className='table-image-icon'><img src={record.imagePath} alt={record.tourName} /></div> </div>),
            //sorter: (a, b) => a.tourName - b.tourName,
            width: 60
        },
        {
            title: 'Package Name',
            dataIndex: 'tourName',
            key: 'tourName',
            //render: (text, record) => (<div className='name-with-icon'><div className='table-image-icon'><img src={record.imagePath} alt={record.tourName} /></div> {record.tourName} </div>),
            sorter: (a, b) => a.tourName - b.tourName,
            width: 140
        },
        {
            title: 'Booked Date',
            dataIndex: 'tourDate',
            key: 'tourDate',
            sorter: (a, b) => moment(a.tourDate).unix() - moment(b.tourDate).unix(),
            width: 130
        },
        {
            title: 'tCreatedAt',
            dataIndex: 'tCreatedAt',
            key: 'tCreatedAt',
            sorter: (a, b) => moment(a.tCreatedAt).unix() - moment(b.tCreatedAt).unix(),
            width: 130
        },
        {
            title: 'User Email',
            dataIndex: 'vEmail',
            key: 'vEmail',
            sorter: (a, b) => a.vEmail - b.vEmail,
            width: 150
        },
        {
            title: 'Unique Number',
            dataIndex: 'uniqueNo',
            key: 'uniqueNo',
            sorter: (a, b) => a.uniqueNo - b.uniqueNo,
            width: 150
        },
        {
            title: 'Service UniqueID',
            dataIndex: 'serviceUniqueId',
            key: 'serviceUniqueId',
            sorter: (a, b) => a.serviceUniqueId - b.serviceUniqueId,
            width: 170
        },
        {
            title: 'BookingID',
            dataIndex: 'bookingId',
            key: 'bookingId',
            sorter: (a, b) => a.bookingId - b.bookingId,
            width: 150
        },
        {
            title: 'Download Required',
            dataIndex: 'downloadRequired',
            key: 'downloadRequired',
            sorter: (a, b) => a.downloadRequired - b.downloadRequired,
            width: 170
        },
        {
            title: 'TourID',
            dataIndex: 'tourId',
            key: 'tourId',
            sorter: (a, b) => a.tourId - b.tourId,
            width: 150
        },
        {
            title: 'Tour OptionID',
            dataIndex: 'tourOptionId',
            key: 'tourOptionId',
            sorter: (a, b) => a.tourOptionId - b.tourOptionId,
            width: 150
        },
        {
            title: 'Tour OptionName',
            dataIndex: 'optionName',
            key: 'optionName',
            sorter: (a, b) => a.optionName - b.optionName,
            width: 180
        },
        {
            title: 'Adult Count',
            dataIndex: 'adultCount',
            key: 'adultCount',
            sorter: (a, b) => a.adultCount - b.adultCount,
            width: 150
        },
        {
            title: 'Child Count',
            dataIndex: 'childCount',
            key: 'childCount',
            sorter: (a, b) => a.childCount - b.childCount,
            width: 150
        },
        {
            title: 'Infant Count',
            dataIndex: 'infantCount',
            key: 'infantCount',
            sorter: (a, b) => a.infantCount - b.infantCount,
            width: 150
        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            key: 'startTime',
            sorter: (a, b) => a.startTime - b.startTime,
            width: 150
        },
        {
            title: 'Pickup',
            dataIndex: 'pickup',
            key: 'pickup',
            sorter: (a, b) => a.pickup - b.pickup,
            width: 90
        },
        {
            title: 'rTemp BookingID',
            dataIndex: 'rTempBookingID',
            key: 'rTempBookingID',
            sorter: (a, b) => a.rTempBookingID - b.rTempBookingID,
            width: 170
        },
        {
            title: 'Checkout PayID',
            dataIndex: 'checkoutPayID',
            key: 'checkoutPayID',
            sorter: (a, b) => a.checkoutPayID - b.checkoutPayID,
            width: 150
        },
        {
            title: 'Error Description',
            dataIndex: 'errorDescription',
            key: 'errorDescription',
            sorter: (a, b) => a.errorDescription - b.errorDescription,
            width: 150
        },
        {
            title: 'Booking ID',
            dataIndex: 'referenceNo',
            key: 'referenceNo',
            sorter: (a, b) => a.referenceNo - b.referenceNo,
            width: 150
        },
        {
            title: 'Lead Person',
            dataIndex: 'vUserName',
            key: 'vUserName',
            sorter: (a, b) => a.vUserName - b.vUserName,
            width: 150
        },
        {
            title: 'User Mobile',
            dataIndex: 'vMobileNo',
            key: 'vMobileNo',
            sorter: (a, b) => a.vUserName - b.vUserName,
            width: 150
        },
        {
            title: 'Booking Status',
            dataIndex: 'payStatus',
            key: 'payStatus',
            sorter: (a, b) => a.iStatus - b.iStatus,
            width: 150
        },
        {
            title: 'User Paid',
            dataIndex: 'tServiceTotal',
            key: 'tServiceTotal',
            sorter: (a, b) => a.tServiceTotal - b.tServiceTotal,
            render: (text, record) => (<div className='spent-col'><small>{record.currency}</small><div className='amount-col'>{text}</div></div>),
            width: 90
        }
    ];
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: 'Download Report',
                }
            ]}
        />
    );
    return (
        <div className='inner-wrapper'>

            <Row>
                <Col>
                    <ul className='statcis-row'>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='payment' viewbox='0 0 15.842 12.5' />
                                </div>
                                <div className='item-upper'>
                                    <small>AED</small>
                                    <h3>{collectedAmount}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Net Collected</p>
                                    <small>So far</small>
                                </div>
                            </div>
                            <SvgIcon className='total-sale-icon' name='total-sale' viewbox='0 0 99.973 46.186' />
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='offer' viewbox='0 0 15.532 15.553' />
                                </div>
                                <div className='item-upper'>
                                    <small>&nbsp;</small>
                                    <h3>{totalBookings}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Total Bookings</p>
                                    <small>All time</small>
                                </div>
                            </div>
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='offer' viewbox='0 0 15.532 15.553' />
                                </div>
                                <div className='item-upper'>
                                    <small>&nbsp;</small>
                                    <h3>{upcomingBooking}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Upcoming Bookings</p>
                                    <small>Total</small>
                                </div>
                            </div>
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='offer' viewbox='0 0 15.532 15.553' />
                                </div>
                                <div className='item-upper'>
                                    <small>&nbsp;</small>
                                    <h3>{completedBooking}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Completed Bookings</p>
                                    <small>Total</small>
                                </div>
                            </div>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row className="filter-row">
                <Col md="6">
                    <div className='filter-left'>
                        <h3>Bookings</h3>
                        <Input onChange={onSearch} suffix={<SvgIcon name='search' viewbox='0 0 15.13 15.134' />} />
                    </div>
                </Col>
                <Col md="6" className='filter-right'>
                    <div className='date-select-row'>
                        <div className='date-col'>
                            <label>Starting Date</label>
                            <DatePicker value={DateFrom} onChange={handleFrom} inputReadOnly placeholder="DD/MM/YYYY" suffixIcon={<SvgIcon name='calendar' viewbox='0 0 38.269 36' />} />
                        </div>
                        <div className='date-col'>
                            <label>End Date</label>
                            <DatePicker value={DateTo} onChange={handleTo} inputReadOnly placeholder="DD/MM/YYYY" suffixIcon={<SvgIcon name='calendar' viewbox='0 0 38.269 36' />} />
                        </div>
                    </div>
                    <Button type='secondary' onClick={handleDateRange} className='icons-btn send-btn'><SvgIcon name='send' viewbox='0 0 26.998 26.995' /></Button>
                    <Dropdown overlayClassName='dropdown-popup-cus' overlay={menu}>
                        <Button onClick={downloadCsv} type='secondary' className='icons-btn'><SvgIcon name='download' viewbox='0 0 15.624 15.996' /></Button>
                    </Dropdown>
                </Col>
            </Row>
            <Row>
                <Col sm='12'>
                    <Table className='custom-table redemption-table' scroll={{ x: "100%", y: "100%" }}  dataSource={dataSource} columns={columns} showSorterTooltip={false} sortUpIcon={<SvgIcon name='heart' viewbox='0 0 14.297 12.626' />}
                        onRow={(record) => {
                            return {
                                onClick: event => {
                                    showModal(record)
                                },
                            };
                        }}
                    />
                </Col>
            </Row>
            <Modal className='redemption-modal' width={500} centered title={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} closeIcon={<SvgIcon name='close' viewbox='0 0 25 25' />}
                footer={
                    <>
                        <Button type='secondary' onClick={()=>{getReport(BookingData.rBookingID)}}><SvgIcon name='download' viewbox='0 0 15.624 15.996' /></Button>
                        <Button type='secondary'><SvgIcon name='share' viewbox='0 0 14.478 16.756' /></Button>
                    </>
                }
            >
                <Row className="upper-details">
                    <Col>
                        <div className='upper-image'>
                            <img src={BookingData.imagePath} alt='Deem Offers' />
                        </div>
                        <h3>{BookingData.tourName}</h3>
                        <p>User Email : {BookingData.vEmail}</p>
                        <Divider />
                    </Col>
                </Row>
                <div className='bottom-details'>
                    <Row>
                        <Col>
                            <label>Lead Person</label>
                        </Col>
                        <Col className='text-right'> {BookingData.vUserName}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Amount</label>
                        </Col>
                        <Col className='text-right'>AED {BookingData.tServiceTotal}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Booking Status</label>
                        </Col>
                        <Col className='text-right'>{BookingData.payStatus}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Payment Mode</label>
                        </Col>
                        <Col className='text-right'>{BookingData.payment_mode}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Transaction ID</label>
                        </Col>
                        <Col className='text-right'>{BookingData.referenceNo}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>User Email</label>
                        </Col>
                        <Col className='text-right'>{BookingData.vEmail}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>User Mobile</label>
                        </Col>
                        <Col className='text-right'>{BookingData.vMobileNo}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Type</label>
                        </Col>
                        <Col className='text-right'>Adult {BookingData.adultCount}, Child {BookingData.childCount}, Infant {BookingData.infantCount}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Date</label>
                        </Col>
                        <Col className='text-right'>{BookingData.tourDate}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Time</label>
                        </Col>
                        <Col className='text-right'>{BookingData.startTime}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Pickup location</label>
                        </Col>
                        <Col className='text-right'>{BookingData.pickup}</Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
};

export default Bookings