import React, { useState } from 'react';
import { Button, Form, Modal, Input, Radio, Checkbox, Select } from 'antd';
import { Col, Row, SvgIcon } from '../../../components/common';
import Slider from "react-slick";
import "../../../assets/scss/plugins/slick-slider/slick.min.scss"; 
import "../../../assets/scss/plugins/slick-slider/slick-theme.min.scss"; 
import './index.scss';

import offerTypeIcon from '../../../assets/images/offer-type.svg';
import outletBranch1 from '../../../assets/images/outlet1.png';
import outletBranch2 from '../../../assets/images/outlet2.png';
import outletBranch3 from '../../../assets/images/rr3.png';

const { TextArea } = Input;

function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}>
          <SvgIcon name='next-arrow' viewbox='0 0 6.14 10.563' />
        </div>
    );
  }
  
  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}>
          <SvgIcon name='prev-arrow' viewbox='0 0 6.14 10.564' />
      </div>
    );
  }

const AddOfferModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2.3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 2.1,
              }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.3,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1.1,
                }
            },
        ]
      };
    const outletBranchData = [
        {key: 1, name: 'Dubai Fish', image: outletBranch1, address: 'Al Karama Center' },
        {key: 2, name: 'Tamoka Cafe', image: outletBranch2, address: 'Al Karama Center' },
        {key: 3, name: 'Dubai Dish', image: outletBranch3, address: 'Al Karama Center' },
        {key: 4, name: 'Tamoka Cafe', image: outletBranch1, address: 'Al Karama Center' },
    ]
    return (
        <>
            <div className='activeoffer-row add-offer' onClick={showModal}>
                <div className='activeoffer-img'>
                    <div className='plus-icon'>
                        <SvgIcon name='plus' viewbox='0 0 21 21' />
                    </div>
                </div>
                <div className='redemptionchart-left'>
                    <h4>Add Offer</h4>
                </div>
            </div>
            <Modal className='addoffer-modal' width={500} centered title={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} closeIcon={<SvgIcon name='close' viewbox='0 0 25 25' />}
                footer={null}
            >
                <h2>Create a Offer</h2>
                <Form
                    name="basic"
                    layout="vertical"
                    className='form-inner'
                >
                    <Row className="upper-header">
                        <Col lg='12'>
                            <h4>Offer Type</h4>
                            <p>Choose the deal format</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <Radio.Group defaultValue="b" buttonStyle="solid">
                                <Radio.Button value="a">
                                    <img src={offerTypeIcon} alt='Offer Type' />
                                    <label>2 for 1</label>
                                </Radio.Button>
                                <Radio.Button value="b">
                                    <img src={offerTypeIcon} alt='Offer Type' />
                                    <label>Discount Type</label>
                                </Radio.Button>
                                <Radio.Button value="c">
                                    <img src={offerTypeIcon} alt='Offer Type' />
                                    <label>Special</label>
                                </Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='6'>
                            <Form.Item
                                label="Offer Details"
                                name="offer_details"
                                rules={[{ required: true }]}
                            >
                                <Input defaultValue='30%' />
                            </Form.Item>
                        </Col>
                        <Col lg='6'>
                            <Form.Item
                                label="Brand"
                                name="brand"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    defaultValue="1"
                                    popupClassName='header-top-select'
                                    placement="bottomRight"
                                    suffixIcon={<SvgIcon name='select-arrow' viewbox='0 0 18 9' />}
                                    options={[
                                        {
                                            value: '1',
                                            label: 'Dubai Fish',
                                        },
                                        {
                                            value: '2',
                                            label: 'Tamoka Cafe',
                                        },
                                        {
                                            value: '3',
                                            label: 'The penthouse',
                                        },
                                        {
                                            value: '4',
                                            label: 'Noepe',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <Form.Item
                                label="Terms & Conditions"
                                name="terms"
                                rules={[{ required: true }]}
                            >
                                <TextArea placeholder='Enter' rows={5} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="upper-header mt-3">
                        <Col xs='7'>
                            <h4>Outlet (Branch)</h4>
                            <p>Choose the outlet</p>
                        </Col>
                        <Col xs='5' className='text-right'>
                            <label>Select All <Checkbox /></label>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <Slider {...settings}>
                                {outletBranchData.map(item =>
                                    (
                                        <div key={item.key}>
                                            <div className='recent-red-card md-card'>
                                                <div className='card-header'>
                                                    <div className='right-img'>
                                                        <img src={item.image} alt="Karama" />
                                                    </div>
                                                </div>
                                                <div className='card-bottom'>
                                                    <h4>{item.name}</h4>
                                                    <p>ID {item.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </Slider>
                        </Col>
                    </Row>

                    <Form.Item className='mb-0'>
                        <Button type="primary" htmlType="submit" block>
                            Create
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
};

export default AddOfferModal