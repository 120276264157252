import React, { useEffect, useState } from "react";

const SvgSprite = (props) => {
  const [svg, setSvg] = useState({});

  useEffect(() => {
    (async () => {
      let svgVersion = "0.0.9";
      console.log("SVG Sprite version : " + svgVersion);
      var isLocalStorage =
          "localStorage" in window && window["localStorage"] !== null,
        data;
      if (
        isLocalStorage &&
        localStorage.getItem("inlineSVGrev") === svgVersion
      ) {
        data = localStorage.getItem("inlineSVGdata");
        setSvg(data);
      } else {
        try {
          const response = await fetch(props.url);
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          const data = await response.text();
          if (data === undefined) {
            throw new Error("Network response text is undefined.");
          }
          if (isLocalStorage) {
            localStorage.setItem("inlineSVGdata", data);
            localStorage.setItem("inlineSVGrev", svgVersion);
          }
          setSvg(data);
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [props.url]);
  return (
    <div
      style={{ display: "none" }}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};

export default SvgSprite;