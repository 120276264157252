import React from "react";
import { Button, Menu } from "antd";
import { useHistory, NavLink } from 'react-router-dom';
import { SvgIcon } from "../../common";
import TPLogo from '../../../assets/images/rr4.png';
import "./index.scss";


const items = [
  { label: <NavLink to="/bookings" activeClassName="selected"><SvgIcon name="redemptions" viewbox="0 0 11.95 11.95" /> Bookings</NavLink>, key: 'menu-2' },
  { label: <NavLink to="/payments" activeClassName="selected"><SvgIcon name="outlet-mgt" viewbox="0 0 11.95 11.95" /> Payments</NavLink>, key: 'menu-4' },
  { label: <NavLink to="/cancellations" activeClassName="selected"><SvgIcon name="outlet-mgt" viewbox="0 0 11.95 11.95" /> Cancellations</NavLink>, key: 'menu-4' },
];

const Sidebar = () => {
  const history = useHistory();
  const nowDate = new Date();
  const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
  const currentDate = longEnUSFormatter.format(nowDate);

  const handleLogout = () => {
    localStorage.clear();
    history.push("/");
  };

  return (
    <div className="sidebar-inner">
      <div className="sidebar-upper">
        <div className="logo-section">
          <img src={TPLogo} alt="logo" />
          <h3>{currentDate}</h3>
        </div>
        <div className="menu-section">
          <Menu items={items} />
        </div>

      </div>
      <div className="sidebar-bottom">

        <div className="logout-bottom">
          <div>
            Logout
          </div>
          <Button  onClick={handleLogout}><SvgIcon name="logout" viewbox="0 0 14.324 12.978" /></Button>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
