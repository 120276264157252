import React from 'react';
import { Select } from 'antd';
import { Col, Row, SvgIcon } from '../../components/common';
import Highcharts from "highcharts";
import Slider from "react-slick";
import HighchartsReact from "highcharts-react-official";
import "../../assets/scss/plugins/slick-slider/slick.min.scss"; 
import "../../assets/scss/plugins/slick-slider/slick-theme.min.scss"; 
import './index.scss';

import recentRedemptions1 from '../../assets/images/rr1.png';
import recentRedemptions2 from '../../assets/images/rr2.png';
import recentRedemptions3 from '../../assets/images/rr3.png';
import recentRedemptions4 from '../../assets/images/rr4.png';
import BuyoneGetOne from '../../assets/images/buyone-getone.svg';
import Platform1 from '../../assets/images/pt1.png';
import Platform2 from '../../assets/images/pt2.png';
import Platform3 from '../../assets/images/pt3.png';
import Platform4 from '../../assets/images/pt4.png';
import AddOfferModal from './AddOfferModal';

function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}>
          <SvgIcon name='next-arrow' viewbox='0 0 6.14 10.563' />
        </div>
    );
  }
  
  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}>
          <SvgIcon name='prev-arrow' viewbox='0 0 6.14 10.564' />
      </div>
    );
  }

const Dashboard = () => {
    const options = {
        chart: {
            type: "column",
            backgroundColor: null,
            height: 308
        },
        credit:{
            enabled: false,
        },
        title: {
            text: ""
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                maxPointWidth: 25
            },
            column: {
                borderRadiusTopLeft: 2,
        	    borderRadiusTopRight: 2,
                opacity: 1,
            }
        },
        legend: {
            enabled: false,
        },
        yAxis: {
            title: null,
            color: 'red',
            className: 'chart-y-lebel',
            gridLineDashStyle: 'longdash',
            gridLineColor: '#334862',
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            className: 'chart-x-lebel',
            lineWidth: 0
        },
        tooltip: {
            className: 'chart-tooltip',
            useHTML: true,
            padding: 0,
            borderWidth: 0,
            formatter: function () {
                return '<div class="chart-tooltip">' + '<div class="tooltip-left">' + '<b>' + this.y + '</b>' + '<br/>' + 'REDEMPTIONS' + '</div>' + '<div class="tooltip-right">' +  this.x + '</div>' + '</div>';
              }
          },        
        series: [
            {
                data: [12000, 8000, 22000, 14000, 50000, 13000, 12000, 12500, 13000, 45000, 14000, 20000],
                color: '#FFB99F',
            }
        ]
    };
    const lineChart1 = {
        chart: {
          type: "spline",
          backgroundColor: null,
          height: "55",
          width: "100",
          margin: 0,
          padding: 0,
          spacing: 0
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            spline: {
            spacing: 0,
            size: "100%",
            showInLegend: false,
            lineWidth: 3,
            lineColor: "#6FC3CE",
            fillColor: false,
            marker: {
                enabled: false,
                symbol: 'circle',
                radius: 8,
                states: {
                    hover: {
                        enabled: false
                    }
                }
            }
          },
        },
        yAxis: {
          gridLineColor: "transparent",
          labels: {
            enabled: false,
          },
          title: {
            enabled: false,
          },
        },
        xAxis: {
          lineColor: "transparent",
          labels: {
            enabled: false,
          },
        },
        series: [
          {
            data: [1, 3, 5, 5.5, 4, 3, 2, 2.5, 3, 1.5, 2, 4, 4.5, 4.8, 7.5, 7, 6,],
          },
        ],
      };
      const lineChart2 = {
        chart: {
          type: "spline",
          backgroundColor: null,
          height: "55",
          width: "100",
          padding: 0,
          margin: 0,
          spacing: 0
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            spline: {
            spacing: 0,
            size: "100%",
            showInLegend: false,
            lineWidth: 3,
            lineColor: "#FF7541",
            fillColor: false,
            marker: {
                enabled: false,
                symbol: 'circle',
                radius: 8,
                states: {
                    hover: {
                        enabled: false
                    }
                }
            }
          },
        },
        yAxis: {
          gridLineColor: "transparent",
          labels: {
            enabled: false,
          },
          title: {
            enabled: false,
          },
        },
        xAxis: {
          lineColor: "transparent",
          labels: {
            enabled: false,
          },
        },
        series: [
          {
            data: [1.5, 1, 2, 3, 2.5, 2, 2.5, 3.5, 4, 5, 4.5, 1, 1.5, 2.5, 3.5, 4.5, 2.5, 1.5,2.5],
          },
        ],
      };
      const lineChart3 = {
        chart: {
          type: "spline",
          backgroundColor: null,
          height: "55",
          width: "100",
          margin: 0,
          padding: 0,
          spacing: 0
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            spline: {
            spacing: 0,
            size: "100%",
            showInLegend: false,
            lineWidth: 3,
            lineColor: "#FF7541",
            fillColor: false,
            radius: 8,
            marker: {
                enabled: false,
                symbol: 'circle',
                radius: 8,
                states: {
                    hover: {
                        enabled: false
                    }
                }
            }
          },
        },
        yAxis: {
          gridLineColor: "transparent",
          labels: {
            enabled: false,
          },
          title: {
            enabled: false,
          },
        },
        xAxis: {
          lineColor: "transparent",
          labels: {
            enabled: false,
          },
        },
        series: [
          {
            data: [3, 2, 1.5, 1.8, 4, 2, 2.5, 3.5, 4.2, 4.8, 5.5, 5.8, 1, 1.3, 2.5, 3.5, 4.5, 2.5, 1.5, 1],
          },
        ],
      };
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
              }
            },
            {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1.4,
                }
            },
            {
                breakpoint: 400,
                settings: {
                  slidesToShow: 1.2,
                }
            },
        ]
      };
    const recentRedemptionsData = [
        {key: 1, name: 'Karama', image: recentRedemptions1, points: '20', id: '64377758785', date: '12th Jan 2022' },
        {key: 2, name: 'Business Bay', image: recentRedemptions2, points: '20', id: '64377758785', date: '12th Jan 2022' },
        {key: 3, name: 'Business Bay', image: recentRedemptions3, points: '20', id: '64377758785', date: '12th Jan 2022' },
        {key: 4, name: 'Business Bay', image: recentRedemptions1, points: '20', id: '64377758785', date: '12th Jan 2022' },
        {key: 5, name: 'Business Bay', image: recentRedemptions4, points: '20', id: '64377758785', date: '12th Jan 2022' },
        {key: 6, name: 'Business Bay', image: recentRedemptions4, points: '20', id: '64377758785', date: '12th Jan 2022' },
    ]
    const platformData = [
        { key: 1, name: 'Deem Offers', image: Platform1, price: '32578' },
        { key: 2, name: 'ENBD Bank', image: Platform2, price: '657348' },
        { key: 3, name: 'Ahli Rewards', image: Platform3, price: '53647' },
        { key: 4, name: 'CBD', image: Platform4, price: '5574' },
        { key: 5, name: 'Deem Offers', image: Platform1, price: '32578' },
        { key: 6, name: 'ENBD Bank', image: Platform2, price: '657348' },
        { key: 7, name: 'Ahli Rewards', image: Platform3, price: '53647' },
        { key: 8, name: 'CBD', image: Platform4, price: '5574' },
        { key: 9, name: 'Deem Offers', image: Platform1, price: '32578' },
        { key: 10, name: 'ENBD Bank', image: Platform2, price: '657348' },
        { key: 11, name: 'Ahli Rewards', image: Platform3, price: '53647' },
        { key: 12, name: 'CBD', image: Platform4, price: '5574' },
    ]
    return (
        <div className='inner-wrapper'>
            <Row>
                <Col>
                    <div className='title-header'>
                        <p>Hi,👋</p>
                        <Select
                            defaultValue="1"
                            popupClassName='header-top-select'
                            suffixIcon={<SvgIcon name='chevron-down' viewbox='0 0 23.616 13.503' />}
                            options={[
                                {
                                    value: '1',
                                    label: 'Tamoka Cafe',
                                },
                                {
                                    value: '2',
                                    label: 'Fish Cafe',
                                },
                                {
                                    value: '3',
                                    label: 'The Penthouse',
                                },
                                {
                                    value: '4',
                                    label: 'Dubai Cafe',
                                },
                            ]}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ul className='statcis-row'>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='payment' viewbox='0 0 15.842 12.5' />
                                </div>
                                <div className='item-upper'>
                                    <small>AED</small>
                                    <h3>54000</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Total Sales</p>
                                    <small>12th Jan 2022</small>
                                </div>
                            </div>
                            <SvgIcon className='total-sale-icon' name='total-sale' viewbox='0 0 99.973 46.186' />
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='offer' viewbox='0 0 15.532 15.553' />
                                </div>
                                <div className='item-upper'>
                                    <small>&nbsp;</small>
                                    <h3>54000</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Total Redemption</p>
                                    <small>12th Jan 2022</small>
                                </div>
                            </div>
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='wallet' viewbox='0 0 15.07 14.873' />
                                </div>
                                <div className='item-upper'>
                                    <small>AED</small>
                                    <h3>54000</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Avg. Saving per user</p>
                                    <small>12th Jan 2022</small>
                                </div>
                            </div>
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='heart' viewbox='0 0 14.297 12.626' />
                                </div>
                                <div className='item-upper'>
                                    <small>&nbsp;</small>
                                    <h3>54000</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Favorited by User</p>
                                    <small>12th Jan 2022</small>
                                </div>
                            </div>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col lg="8">
                    <div className='md-card left-chart-card'>
                        <div className='card-header d-flex justify-content-between mb-3'>
                            <h4>Redemptions</h4>
                            <label>Last 12 months</label>
                        </div>
                        <div className='card-body'>
                            <HighchartsReact highcharts={Highcharts} options={options} />
                        </div>
                    </div>
                </Col>
                <Col lg="4">
                    <div className='md-card right-chart-card'>
                        <div className='card-header d-flex justify-content-between mb-3'>
                            <div>
                                <h4 className='mb-1'>Offers</h4>
                                <label>Redemptions</label>
                            </div>
                            <div>
                                <label>Last 12 months</label>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='offer-redemptionchart-row'>
                                <div className='redemptionchart-left'>
                                    <p>AED</p>
                                    <h4>54000</h4>
                                    <label>Buy 1 get 1 free</label>
                                </div>
                                <div className='redemptionchart-right'>
                                    <HighchartsReact highcharts={Highcharts} options={lineChart1} />
                                </div>
                            </div>
                            <div className='offer-redemptionchart-row row2'>
                                <div className='redemptionchart-left'>
                                    <p>AED</p>
                                    <h4>32578</h4>
                                    <label>Discount of 20%</label>
                                </div>
                                <div className='redemptionchart-right'>
                                    <HighchartsReact highcharts={Highcharts} options={lineChart2} />
                                </div>
                            </div>
                            <div className='offer-redemptionchart-row'>
                                <div className='redemptionchart-left'>
                                    <p>AED</p>
                                    <h4>32578</h4>
                                    <label>Special offer</label>
                                </div>
                                <div className='redemptionchart-right'>
                                    <HighchartsReact highcharts={Highcharts} options={lineChart3} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="recent-redemptions-row">
                <Col sm='12'>
                    <h3 className='title'>Recent Redemptions</h3>
                    <Slider {...settings}>
                        {recentRedemptionsData.map(item =>
                            (
                                <div key={item.key}>
                                    <div className='recent-red-card md-card'>
                                        <div className='card-header'>
                                            <div>
                                                <p>AED</p>
                                                <h3>{item.points}</h3>
                                            </div>
                                            <div className='right-img'>
                                                <img src={item.image} alt="Karama" />
                                            </div>
                                        </div>
                                        <div className='card-bottom'>
                                            <h4>{item.name}</h4>
                                            <p>ID {item.id} / {item.date}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </Slider>
                </Col>
            </Row>
            <Row>
                <Col lg="8">
                    <div className='md-card platforms-card'>
                        <div className='card-header d-flex justify-content-between mb-2'>
                            <div>
                                <h4 className='mb-1'>Platforms</h4>
                                <label>Last 12 months</label>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='platform-list'>
                                {platformData.map(item =>
                                    (
                                        <div className='platform-list-item' key={item.key}>
                                            <div className='platform-list-left'>
                                                <div className='left-image'>
                                                    <img src={item.image} alt='Deem Offers' />
                                                </div>
                                                <div>
                                                    {item.name}
                                                </div>
                                            </div>
                                            <div className='platform-list-right'>
                                                <small>AED</small>
                                                <h3>{item.price}</h3>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg="4">
                    <div className='md-card activeoffer-card'>
                        <div className='card-header d-flex justify-content-between mb-3'>
                            <div>
                                <h4 className='mb-1'>Active Offers</h4>
                                <label>2 Offers are active</label>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='activeoffer-row'>
                                <div className='activeoffer-img'>
                                    <img src={BuyoneGetOne} alt="Buy 1 get 1 free" />
                                </div>
                                <div className='redemptionchart-left'>
                                    <h4>Buy 1 get 1 free</h4>
                                    <label>12th Jan 2022</label>
                                </div>
                            </div>
                            <div className='activeoffer-row'>
                                <div className='activeoffer-img'>
                                    <img src={BuyoneGetOne} alt="Buy 1 get 1 free" />
                                </div>
                                <div className='redemptionchart-left'>
                                    <h4>Buy 1 get 1 free</h4>
                                    <label>12th Jan 2022</label>
                                </div>
                            </div>
                            <AddOfferModal />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default Dashboard