import React from "react";
import Dashboard from "./containers/Dashboard";
import Payments from "./containers/Payments";
import Bookings from "./containers/Bookings";
import Login from "./containers/Login";
import Cancellations from "./containers/Cancellations";

// Layout Types
import { defaultLayout, authLayout } from "./layouts";

// Route Views

export const rotues = [
  {
    path: "/",
    layout: authLayout,
    exact:true,
    component: () => <Login />,
  },
  {
    path: "/payments",
    layout: defaultLayout,
    component: () => <Payments />,
  },
  {
    path: "/cancellations",
    layout: defaultLayout,
    component: () => <Cancellations />,
  },
  {
    path: "/bookings",
    layout: defaultLayout,
    component: () => <Bookings />,
  }
];
