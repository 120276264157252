import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { SvgIcon, FloatLabel } from '../../components/common';
import { showAlert } from "../../common/alert-messages";
import { useHistory } from 'react-router-dom';
import HttpClient from "../../api/HttpClient";
import './index.scss';

import LogoIcon from '../../assets/images/logo-icon.svg';
import BannerVideo from '../../assets/video/bg_merchant.mp4';

const Login = () => {
    const history = useHistory();
    const [vEmail, setvEmail] = useState("");
    const [vPassword, setvPassword] = useState("");

    const handle = (e) => { setvPassword(e.target.value) };
    const handleEmailChange = (e) => { setvEmail(e.target.value) };


    const handleEmailClick = () => {
        //console.log(vEmail);
        if (vEmail !== "") {
            if (vEmail.trim().length >= 2) {
                if (vEmail.match("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$") !== null)
                    checkEmail(vEmail, vPassword);
                else showAlert.error("Please enter Valid Email.");
            }
        } else {
            if (vEmail === "") showAlert.error("Please Enter your email.");
        }
    };


    const checkEmail = async (vEmail, vPassword) => {
        try {
            const data = new FormData();
            data.append("vEmail", vEmail);
            data.append("vPassword", vPassword);
            const response = await HttpClient.post("/bookings/login", data);
            const json = response.data;
            if (json.status === 1) {
                //console.log(json.DATA[0]);
                localStorage.setItem("Login","Yes");
                history.push("/bookings");
            } else {

                showAlert.error(json.MESSAGE);
            }
        } catch (error) {
            console.error(error);
            showAlert.error("Oops! Wrong Username/Password.");
        }
    };

    return (
        <div className='login-wrapper'>
            <video playsInline autoPlay muted loop className="banner-video">
              <source src={BannerVideo} />
            </video>
            <div className='login-inner'>
                <div className='login-head' data-aos="fade-down" data-aos-duration="500">
                    <img src={LogoIcon} alt='logo' />
                    <h1>Welcome</h1>
                    <h4>to our bookings dashboard</h4>
                </div>
                <div className='login-card' data-aos="zoom-in" data-aos-duration="600">
                    <Form
                        name="basic"
                        autoComplete="off"
                        layout="vertical"
                        className='login-form'
                    >
                        <Form.Item name="username">
                            <Input prefix={<SvgIcon name='user-icon' viewbox='0 0 20 20' />} value={vEmail} placeholder="Email" onChange={handleEmailChange} />
                        </Form.Item>
                        <Form.Item>
                            <FloatLabel name="password">
                                <Input type="password" prefix={<SvgIcon name='password-icon' viewbox='0 0 20 20' />} value={vPassword} onChange={handle} placeholder="Password" />
                            </FloatLabel>
                        </Form.Item>
                        <Form.Item className='m-0 py-3'>
                            <Button onClick={handleEmailClick} className='with-arrow' type="primary" htmlType="submit" block>
                                Sign In <SvgIcon name='chevron-right' viewbox='0 0 9.639 16.45' />
                            </Button>
                        </Form.Item>
                        <p>2023 © TravellerPassLLC</p>
                    </Form>
                </div>
            </div>
        </div>
    )
};

export default Login